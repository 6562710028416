import React from "react"
import { colors } from "../../utils"

export function CallToAction({ onClick, content = "Learn more" }) {
  return (
    <button
      style={{
        background: colors.green,
        borderRadius: "8px",
        boxShadow: "0px 2px 4px rgba(33, 37, 41, 0.08)",
        textAlign: "center",
        font: "500 16px/24px Poppins",
        letterSpacing: "0px",
        color: "#ffffff",
        border: "none",
        padding: "17px 48px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        cursor: "pointer",
        marginTop: "80px",
      }}
      onClick={onClick}
    >
      {content}
    </button>
  )
}
