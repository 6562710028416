import React from "react"
import Layout from "../components/layout"
import { PasswordGuardContext } from "../components/password-guard.context"
import Thanks from "../components/section-one/thanks"
import SEO from "../components/seo"

const ThankYouPage = () => {
  const { isVerified } = React.useContext(PasswordGuardContext)
  if (isVerified)
    return (
      <Layout>
        <SEO lang="en" title="Home" />
        <Thanks />
      </Layout>
    )

  return null
}

export default ThankYouPage
