import { graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import bg from "../../images/powr.svg"
import { colors } from "../../utils"
import { CallToAction } from "./callToAction"
import "./hero.css"
import { Logo } from "./Logo"

export default function Thanks() {
  const data = useStaticQuery(query)
  return (
    <section className="hero-section hero-section-3">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-5 col-lg-5">
            <Logo />
            <div className="hero-content-left ptb-100">
              <h1
                style={{ color: colors.basic, fontSize: 48, marginBottom: 24 }}
              >
                Thank you for contacting us!
              </h1>
              <p
                style={{
                  color: colors.basic,
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 400,
                }}
                className="lead lead-bordered"
              >
                Vestibulum commodo sapien non elit porttitor, vitae volutpat
                nibh mollis. Nulla porta risus id neque tempor, in efficitur
                justo imperdiet.
              </p>
              <CallToAction content="Go back" onClick={() => navigate("/")} />
            </div>
          </div>
          <div className="col-md-7 col-lg-7">
            <div className="hero-animation-img">
              <Img
                className="img-fluid d-block m-auto animation-one"
                fluid={data.file.childImageSharp.fluid}
                alt="animation"
              />
            </div>
          </div>
        </div>
        <img src={bg} className="shape-image" alt="shape" />
      </div>
    </section>
  )
}

export const query = graphql`
  query ThanksQuery {
    file(relativePath: { eq: "section-all.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
